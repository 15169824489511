.main-page {
    width: 100vw;
    height: 100vh;
}

@font-face {
    font-family: 'Mirador';
    src: url("../../fonts/Mirador/Rene\ Bieder\ -\ Mirador\ Book\ DEMO.otf");
}

@font-face {
    font-family: 'Mirador';
    font-weight: bold;
    src: url("../../fonts/Mirador/Rene\ Bieder\ -\ Mirador\ Bold\ DEMO.otf");
}

.main-page h1 {
    position: absolute;
    top: 3.5vh;
    font-family: 'Mirador';

    width: 100%;
    text-align: center;
    font-size: 40px;

    margin: 0;
    padding: 0;
}

.main-page h2 {
    transform: rotate(-90deg);
    position: absolute;
    top: 48vh;
    left: -40vw;
    font-family: 'Mirador';

    width: 100%;
    text-align: center;
    font-size: 40px;

    margin: 0;
    padding: 0;
}

@media (max-width: 400px) {
    h2, h3 {
        display: none;
    }
}

.main-page h3 {
    transform: rotate(90deg);
    position: absolute;
    top: 48vh;
    right: -40vw;
    font-family: 'Mirador';

    width: 100%;
    text-align: center;
    font-size: 40px;

    margin: 0;
    padding: 0;
}

.main-page h4 {
    position: absolute;
    bottom: 3.5vh;
    font-family: 'Mirador';

    width: 100%;
    text-align: center;
    font-size: 40px;

    margin: 0;
    padding: 0;
}

/* ==== BLACK === */
.black {
    background: #1C232D;
}

.black svg {
    stroke: #EEE9E6 !important; 
}

.black h4,
.black h3,
.black h2, 
.black h1 {
    color: #F48B0C !important; 
}

/* ==== GREEN === */
.green {
    background: #157575;
}

.green svg {
    stroke: #EAD7C7;
}

.green h4,
.green h3,
.green h2, 
.green h1 {
    color: white !important;
}

/* ==== DARK GREEN  === */
.dark-green {
    background: #002939;
}

.dark-green svg {
    stroke: #E4CFA9;
}

.dark-green h4, 
.dark-green h3, 
.dark-green h2, 
.dark-green h1 {
    color: #E4CFA9 !important;
}

/* ==== RED === */
.red {
    background: beige;
}

.red svg {
    stroke: #E91515;
}

.red h4, 
.red h3, 
.red h2, 
.red h1 {
    color: #E91515 !important;
}

/* ==== BLUE ZODIAC === */
.blue-zodiac {
    background: #3A4257;
}

.blue-zodiac svg {
    stroke: #F0DDBc
}

.blue-zodiac h4, 
.blue-zodiac h3, 
.blue-zodiac h2, 
.blue-zodiac h1 {
    color: white !important;
}

/* ==== PURPLE === */
.purple {
    background: #3B2D60;
}

.purple svg {
    stroke: #E78DD2
}

.purple h4, 
.purple h3, 
.purple h2, 
.purple h1 {
    color: #FEFEFE !important;
}

/* ==== YELLOW === */
.yellow {
    background: #3F3F3F ;
}

.yellow svg {
    stroke: #FEBD44;
}

.yellow h4, 
.yellow h3, 
.yellow h2, 
.yellow h1 {
    color: #F1C2D0 !important;
}


/* ==== BLUE === */
.blue {
    background: #282c34 ;
}

.blue svg {
    stroke: #61dafb;
}

.blue h4, 
.blue h3, 
.blue h2, 
.blue h1 {
    color: white !important;
}

